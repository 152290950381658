*,
:after,
:before {
  box-sizing: inherit;
}

/**********TAG SELECTORS**********/

html {
  box-sizing: border-box;
  font-size: 100%;
}

body {
  /* background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover; */
  background-color: rgb(243, 242, 242);
  background-image: url("./assets/images/bg2.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  background-size: cover;

  text-align: center;
  font-family: "Montserrat", sans-serif;
  max-width: 100%;
  min-height: 100vh;
  height: auto;
  letter-spacing: 0.1em;
  
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

h1 {
  color: #f1f4f7;
  border-bottom: 3px solid #024873;
  box-shadow: inset 0 -4px 0 #024873;
  border: 2px solid #024873;
  background-color: #024873;
  max-width: 300px;
  margin: 0 auto 15px;
  padding: 10px;
  border-radius: 10px;
  font-size: x-large;
}

h2 {
  color: #000102;
  border-bottom: 3px solid #f1f4f7;
  box-shadow: inset 0 -4px 0 #f1f4f7;
  border: 2px solid #f1f4f7;
  background-color: #f1f4f7;
  max-width: 400px;
  margin: 0 auto 15px;
  padding: 10px;
  border-radius: 10px;
  font-size: large;
}

h3{
  color: rgb(21, 22, 22);
  border-bottom: 3px solid #73e2a7;
  box-shadow: inset 0 -4px 0 #73e2a7;
  border: 2px solid #73e2a7;
  background-color: #73e2a7;
  max-width: 400px;
  margin: 0 auto 15px;
  padding: 10px;
  border-radius: 10px;
}

h5{
  color:rgb(0, 0, 0);
  font-size: x-large !important;
}

hr {
  border-style: none;
  border-top-style: dotted;
  border-color: rgb(196, 196, 196);
  border-width: 5px;
  width: 100px;
}

/**********CLASS SELECTORS**********/

.logo {
  width: 110px;
  height: 110px;
  display: block;
  margin: 35px auto;
  border-radius: 100%;
}

.links {
  display: block;
  max-width: 550px;
  margin: 0 auto 15px;
  margin-bottom: -8px;
  padding: 15px 20px;
  font-size: 0.95rem;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  color: black;
  border-radius: 10px;
  cursor: pointer;
}

.links .links:hover {
  background-color: #fff;
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.links .links_first {
  border-bottom: 3px solid #80d6ff;
  box-shadow: inset 0 -4px 0 #80d6ff;
  border: 2px solid #80d6ff;
  background-color: #80d6ff;
}

.links .links_second {
  border-bottom: 3px solid #ffa093;
  box-shadow: inset 0 -4px 0 #ffa093;
  border: 2px solid #ffa093;
  background-color: #ffa093;
}

.links .links_third {
  border-bottom: 3px solid #bedde8;
  box-shadow: inset 0 -4px 0 #bedde8;

  border: 2px solid #bedde8;
  background-color: #bedde8;
}

.links .links_fourth {
  box-shadow: inset 0 -4px 0 #73e2a7;

  border: 2px solid #73e2a7;
  background-color: #73e2a7;
}

.links .links_fifth {
  border-bottom: 3px solid #ffc15e;
  box-shadow: inset 0 -4px 0 #ffc15e;
  border: 2px solid #ffc15e;
  background-color: #ffc15e;
}

.channels {
  display: flex;
  max-width: 400px;
  margin: 0 auto;
}

.channels .channels_item {
  flex: 5;
  margin-right: 5px;
  transition: transform 0.2s;
  color: #024873;
}

.channels_item:hover {
  transform: scale(1.25) rotate(1turn);
  transition: transform 1.5s;
}

/**********ID SELECTORS**********/
/*ID Selectors can only be ussed once per page*/
/*

#heading {
  color: blue
}

*/
